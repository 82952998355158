import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  addLocation,
  editLocation as editLocationFn,
  askuploadtype,
  imageuploadv2,
  updateimagetoCdn,
} from "../API";
import { toast } from "react-toastify";
import { MdCloudUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Loader from "./Loader";
import * as yup from "yup";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxLocation from "./MapboxLocation";
import { useSelector } from "react-redux";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { select } from "d3";

const addLocationModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "var(--primaryColor)",
  border: "none",
  boxShadow: 24,
  borderRadius: "8px",
};

const ITEM_HEIGHT = 30;

const AddLocationModal = ({
  addLocationModalOpen,
  setAddLocationModalOpen,
  fetchLocationsByType,
  setLocationsList,
  updateLocationGroupIds,
  groupTypeOptions,
  editLocation,
  editData,
}) => {
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [enableShowOnMap, setEnableShowOnMap] = useState(false);
  const [showOnMap, setShowOnMap] = useState(true);
  const [useRobotLocation, setUseRobotLocation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [useSlam, setUseSlam] = useState(true);
  const [showMarker, setShowMarker] = useState(false);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  const [selectedRobot, setSelectedRobot] = useState("");
  const token = localStorage.getItem("token");

  const [locationTypeOptions, setLocationTypeOptions] = useState([
    "DROPOFF",
    "HOME",
    "PICKUP",
    "RELOCALIZATION",
    "WANDER",
  ]);

  const editFormSubmit = (values, { resetForm }) => {
    if (values?.groupType !== "Custom") {
      values.group = values.groupType;
    }
      // console.log(values, "FORM VALUES");
    if (imageAsset) {
      // let formData = new FormData();
      // formData.append("file", file);
      // console.log("FILE Before", file)
      let bodyFormData = new FormData();

      askuploadtype(file.type, token)
        .then((result) => {
          bodyFormData.append("file", file);
          // console.log(result.data.data.uploadURL, "ASDK RESULT");
          updateimagetoCdn(result.data.data.uploadURL, bodyFormData, token)
            .then((res) => {
              // console.log(res.data.result);
              values.imageLocationUrl = res.data.result.variants[0];
              editLocationFn(editData?.locationDetails._id, values)
                .then((res) => {
                  setLoader(false);
                  setAddLocationModalOpen(false);
                  toast.success("Edited Location successfully!");
                  resetForm({ values: "" });
                  fetchLocationsByType()
                    .then((result) => {
                      if (result.status === 200) {
                        setLocationsList(result?.data?.data);
                        updateLocationGroupIds(result?.data?.data);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                })
                .catch((err) => {
                  setLoader(false);
                  setAddLocationModalOpen(false);
                  console.error(err);
                  toast.error(err.message);
                  // toast.error("Something went wrong while editing location!");
                });
              setLoader(true);
            })
            .catch((err) => {
              console.log("Error in Image to CDN Upload", err);
            });
        })
        .catch((err) => {
          console.log("Error in Ask Image Upload", err);
        });
      setLoader(true);
      // imageuploadv2(token, imageAsset)
      //   .then((res) => {
      //     console.log("Image Upload response", res);
      //     formik.setFieldValue("imageLocationUrl", res.data.path);
      //   })
      //   .catch((err) => {
      //     console.log("Error in Image Upload", err);
      //   });
    } else {
      editLocationFn(editData?.locationDetails._id, values)
        .then((res) => {
          setLoader(false);
          setAddLocationModalOpen(false);
          toast.success("Edited Location successfully!");
          resetForm({ values: "" });
          fetchLocationsByType()
            .then((result) => {
              if (result.status === 200) {
                setLocationsList(result?.data?.data);
                updateLocationGroupIds(result?.data?.data);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((err) => {
          setLoader(false);
          setAddLocationModalOpen(false);
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong while editing location!");
        });
    }

    setLoader(true);
  };

  const addFormSubmit = (values, { resetForm }) => {
    // console.log("VALUES", values);
    if (values?.groupType !== "Custom") {
      values.group = values.groupType;
    }
    if (imageAsset) {
      // let formData = new FormData();
      // formData.append("file", file);
      // console.log("FILE Before", file)
      let bodyFormData = new FormData();

      askuploadtype(file.type, token)
        .then((result) => {
          bodyFormData.append("file", file);
          console.log(result.data.data.uploadURL, "ASDK RESULT");
          updateimagetoCdn(result.data.data.uploadURL, bodyFormData, token)
            .then((res) => {
              console.log(res.data.result);
              values.imageLocationUrl = res.data.result.variants[0];
              addLocation(fleetData._id, values)
                .then((res) => {
                  setLoader(false);
                  setAddLocationModalOpen(false);
                  toast.success("Added Location successfully!");
                  resetForm({ values: "" });
                  setIsLoading(false);
                  setImageAsset(null);
                  setFile(null);
                  fetchLocationsByType()
                    .then((result) => {
                      if (result.status === 200) {
                        setLocationsList(result?.data?.data);
                        updateLocationGroupIds(result?.data?.data);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                })
                .catch((err) => {
                  setLoader(false);
                  setAddLocationModalOpen(false);
                  console.error(err);
                  toast.error(err.message);
                  // toast.error("Something went wrong while adding location!")
                });
              setLoader(true);
            })
            .catch((err) => {
              console.log("Error in Image to CDN Upload", err);
            });
        })
        .catch((err) => {
          console.log("Error in Ask Image Upload", err);
        });
      setLoader(true);
      // imageuploadv2(token, imageAsset)
      //   .then((res) => {
      //     console.log("Image Upload response", res);
      //     formik.setFieldValue("imageLocationUrl", res.data.path);
      //   })
      //   .catch((err) => {
      //     console.log("Error in Image Upload", err);
      //   });
    } else {
      addLocation(fleetData._id, values)
        .then((res) => {
          setLoader(false);
          setAddLocationModalOpen(false);
          toast.success("Added Location successfully!");
          resetForm({ values: "" });
          setIsLoading(false);
          setImageAsset(null);
          setFile(null);
          fetchLocationsByType()
            .then((result) => {
              if (result.status === 200) {
                setLocationsList(result?.data?.data);
                updateLocationGroupIds(result?.data?.data);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((err) => {
          setLoader(false);
          setAddLocationModalOpen(false);
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong while adding location!")
        });
      setLoader(true);
    }
  };

  const uploadImage = (e) => {
    // console.log("UPLOAD IMAGE EVENT", e);
    let file = e.target.files[0];
    setFile(file);
    // console.log(file, "FILE");
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);
    // console.log(url);

    reader.onloadend = function (e) {
      setImageAsset(reader.result);
      // console.log("RESULT", reader.result);
    };
  };

  const validationSchema = yup.object({
    locationName: yup.string().required("Location Name is required"),
    locationType: yup
      .string()
      .typeError("Location Type must be string.")
      .oneOf(locationTypeOptions)
      .required("Location Type is required."),
    latitude: yup.string(),
    longitude: yup.string(),
    altitude: yup.string(),
    headingAngle: yup.string(),
    positionX: yup.string(),
    positionY: yup.string(),
    positionZ: yup.string(),
    positionYaw: yup.string(),
    status: yup.boolean(),
    imageLocationUrl: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      locationName: "",
      locationType: "",
      groupType: "",
      group: "",
      latitude: "",
      longitude: "",
      altitude:"",
      headingAngle: "",
      positionX: "",
      positionY: "",
      positionZ: "",
      positionYaw: "",
      status: true,
      imageLocationUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: editLocation ? editFormSubmit : addFormSubmit,

    //  (values, { resetForm }) => {
    //   // console.log("VALUES", values);
    //   if(values?.groupType !== "Custom"){
    //     values.group = values.groupType;
    //   }
    //   if (imageAsset) {
    //     // let formData = new FormData();
    //     // formData.append("file", file);
    //     // console.log("FILE Before", file)
    //     let bodyFormData = new FormData();

    //     askuploadtype(file.type, token)
    //       .then((result) => {
    //         bodyFormData.append("file", file);
    //         console.log(result.data.data.uploadURL, "ASDK RESULT");
    //         updateimagetoCdn(result.data.data.uploadURL, bodyFormData, token)
    //           .then((res) => {
    //             console.log(res.data.result);
    //             values.imageLocationUrl = res.data.result.variants[0];
    //             addLocation(fleetData._id, values)
    //               .then((res) => {
    //                 setLoader(false);
    //                 setAddLocationModalOpen(false);
    //                 toast.success("Added Location successfully!");
    //                 resetForm({ values: "" });
    //                 setIsLoading(false);
    //                 setImageAsset(null);
    //                 setFile(null);
    //                 fetchLocationsByType()
    //                   .then((result) => {
    //                     if (result.status === 200) {
    //                       setLocationsList(result?.data?.data);
    //                       updateLocationGroupIds(result?.data?.data)
    //                     }
    //                   })
    //                   .catch((error) => {
    //                     console.error(error);
    //                   });
    //               })
    //               .catch((err) => {
    //                 setLoader(false);
    //                 setAddLocationModalOpen(false);
    //                 console.error(err);
    //                 toast.error(err.message);
    //                 // toast.error("Something went wrong while adding location!")
    //               });
    //             setLoader(true);
    //           })
    //           .catch((err) => {
    //             console.log("Error in Image to CDN Upload", err);
    //           });
    //       })
    //       .catch((err) => {
    //         console.log("Error in Ask Image Upload", err);
    //       });
    //     setLoader(true);
    //     // imageuploadv2(token, imageAsset)
    //     //   .then((res) => {
    //     //     console.log("Image Upload response", res);
    //     //     formik.setFieldValue("imageLocationUrl", res.data.path);
    //     //   })
    //     //   .catch((err) => {
    //     //     console.log("Error in Image Upload", err);
    //     //   });
    //   } else {
    //     addLocation(fleetData._id, values)
    //       .then((res) => {
    //         setLoader(false);
    //         setAddLocationModalOpen(false);
    //         toast.success("Added Location successfully!");
    //         resetForm({ values: "" });
    //         setIsLoading(false);
    //         setImageAsset(null);
    //         setFile(null);
    //         fetchLocationsByType()
    //           .then((result) => {
    //             if (result.status === 200) {
    //               setLocationsList(result?.data?.data);
    //               updateLocationGroupIds(result?.data?.data)
    //             }
    //           })
    //           .catch((error) => {
    //             console.error(error);
    //           });
    //       })
    //       .catch((err) => {
    //         setLoader(false);
    //         setAddLocationModalOpen(false);
    //         console.error(err);
    //         toast.error(err.message);
    //         // toast.error("Something went wrong while adding location!")
    //       });
    //     setLoader(true);
    //   }
    // },
  });

  useEffect(() => {
    setShowAdvancedSettings(() => false);
    if (editLocation && addLocationModalOpen) {
      formik.setValues({
        locationName: editData?.locationDetails?.locationName
          ? editData?.locationDetails?.locationName
          : "",
        locationType: editData?.locationDetails?.locationType
          ? editData?.locationDetails?.locationType
          : "",
        groupType: editData?.locationDetails?.group,
        group: "",
        latitude: editData?.locationDetails?.latitude
          ? editData?.locationDetails?.latitude
          : "",
        longitude: editData?.locationDetails?.longitude
          ? editData?.locationDetails.longitude
          : "",
        headingAngle: editData?.locationDetails?.headingAngle
          ? editData?.locationDetails.headingAngle
          : "",
        positionX: editData?.locationDetails?.positionX
          ? editData?.locationDetails.positionX
          : "",
        positionY: editData?.locationDetails?.positionY
          ? editData?.locationDetails.positionY
          : "",
        positionZ: editData?.locationDetails?.positionZ
          ? editData?.locationDetails.positionZ
          : "",
        positionYaw: editData?.locationDetails?.positionYaw
          ? editData?.locationDetails.positionYaw
          : "",
        status: editData?.locationDetails?.status
          ? editData?.locationDetails.status
          : true,
          imageLocationUrl: editData?.locationDetails?.imageLocationUrl
            ? editData?.locationDetails.imageLocationUrl
            : "",
            altitude: editData?.locationDetails?.altitude
            ? editData?.locationDetails.altitude
            : "",
        });
    }
  }, [editLocation, addLocationModalOpen]);

  useEffect(() => {
    if (selectedRobot && selectedRobot !== "") {
      let robotData = fleetData?.robots.find(
        (robot) => robot.robotId === selectedRobot
      );
      formik.setValues({
        locationName: formik.values.locationName,
        locationType: formik.values.locationType,
        groupType: formik.values.groupType,
        group: formik.values.group,
        latitude: useSlam ? robotData.latitudeSlam : robotData.latitude,
        longitude: useSlam ? robotData.longitudeSlam : robotData.longitude,
        altitude:useSlam ? robotData.altitudeSlam : robotData.altitude,
        headingAngle: useSlam ? robotData.headingSlam : robotData.heading,
        // latitude: robotData.latitudeSlam,
        // longitude: robotData.longitudeSlam,
        // headingAngle: robotData.headingSlam ,
        positionX: formik.values.positionX,
        positionY: formik.values.positionY,
        positionZ: formik.values.positionZ,
        positionYaw: formik.values.positionYaw,
        status: formik.values.status,
      });

      setShowMarker(() => isLatLongValid());
      // formik.setValues({locationName: "", locationType: "", groupType : "", group: "", latitude: "", longitude : "",headingAngle: "", positionX: "", positionY: "", positionYaw: "",  status: true});
    } 
  }, [selectedRobot, useSlam]);

  useEffect(() => {
    if (selectedRobot && selectedRobot != "") {
      let robotData = fleetData?.robots.find(
        (robot) => robot.robotId === selectedRobot
      );

      if (
        (formik.values.latitude !== (useSlam ? robotData.latitudeSlam : robotData.latitude)) ||
        (formik.values.longitude !== (useSlam ? robotData.longitudeSlam : robotData.longitude))||
        (formik.values.altitude !== (useSlam ? robotData.altitudeSlam : robotData.altitude))||
        (formik.values.headingAngle !== (useSlam ? robotData.headingSlam : robotData.heading))
      ) {
        setSelectedRobot("");
      }
    }
  }, [
    formik.values.latitude,
    formik.values.longitude,
    formik.values.altitude,
    formik.values.headingAngle,
  ]);

  const isLatLongValid = () => {
    const lat = parseFloat(formik.values.latitude);
    const lng = parseFloat(formik.values.longitude);
    const angle = parseFloat(formik.values.headingAngle);

    const isValidLatitude = !isNaN(lat) && lat >= -90 && lat <= 90;
    const isValidLongitude = !isNaN(lng) && lng >= -180 && lng <= 180;
    const isValidHeading = !isNaN(angle) && angle >= -360 && angle <= 360;

    return isValidLatitude && isValidLongitude && isValidHeading;
  };

  const handleChange = (e) => {
    setSelectedRobot(() => e.target.value);
    // setAnchorEl(null);
    // setUseRobotLocation(false);
    updateFormikValues(e.target.value);
    // setUseRobotLocation((prev) => !prev)
  };

  const updateFormikValues = (selectedRobot) => {
    if (selectedRobot && selectedRobot !== "") {
      let robotData = fleetData?.robots.find(
        (robot) => robot.robotId === selectedRobot
      );
      formik.setValues({
        locationName: formik.values.locationName,
        locationType: formik.values.locationType,
        latitude: robotData.latitudeSlam,
        longitude: robotData.longitudeSlam,
        altitude: robotData.altitudeSlam,
        headingAngle: robotData.headingSlam,
        positionX: robotData.values.positionX,
        positionY: robotData.values.positionY,
        positionZ: robotData.values.positionZ,
        positionYaw: robotData.values.positionYaw,
        status: formik.values.status,
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect(() => {
  //   if(isLatLongValid()){
  //     setEnableShowOnMap(true);
  //   }
  //   else{
  //     setEnableShowOnMap(false);
  //   }
  // },[formik.values.latitude, formik.values.longitude, formik.values.headingAngle])

  return (
    <Modal
      open={addLocationModalOpen}
      //   onHide={() => setAddLocationModalOpen(false)}
      backdrop="static"
      keyboard="false"
    >
      <Box
        sx={{
          ...addLocationModalStyle,
          width: isMobile ? "100%" : "80%",
          height: isMobile ? "100%" : "auto",
        }}
      >
        {loader ? (
          <div className="flex flex-col items-center justify-center p-4">
            <Loader />
            <span className="text-textColor">Adding Location...</span>
          </div>
        ) : (
          // <div className={`Order_modal_wrapper ${(showOnMap && enableShowOnMap)?"h-[90vh]":"h-[70vh]"}`}>
          <div
            className={`Order_modal_wrapper ${
              showOnMap || showAdvancedSettings ? "h-[90vh]" : "h-fit"
            }`}
          >
            <div className="!p-4 d-flex-center justify-center OrderModal_heading_wrapper">
              <span className="Order_modal_h3">
                {" "}
                {editLocation ? "Edit" : "Add"} Location
              </span>
              {/* <div className="flex justify-between w-[60%]">
                <div className="flex justify-between items-center text-textColor">
                  <InputLabel id="demo-simple-select-helper-label" className=" text-center mr-2 pr-1 text-textColor font-semibold">Select Robot : </InputLabel>
                  <Select 
                  value={selectedRobot}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{height: "40px", width: "180px" , color:"white", border:"1px solid white", '& .MuiSelect-icon': {
          color: 'white',},
}}
                  className=""
                >
                  {fleetData.robots.map((robot,index) => (
                    <MenuItem key={index} value={robot.robotId} style={{}}>
                      {robot.robotId}
                    </MenuItem>
                  ))}
                  </Select>
                </div>
                <div className={`flex items-center gap-1 hover:cursor-pointer font-semibold ${useRobotLocation?"text-green-300":"text-textColor"}`} onClick={() => {
                  setUseRobotLocation((prev) => !prev)
                  if(selectedRobot && selectedRobot!==""){
                    let robotData = fleetData?.robots.find((robot)=>robot.robotId===selectedRobot)
                    formik.setValues({locationName: formik.values.locationName, locationType: formik.values.locationType, latitude: robotData.latitudeSlam, longitude : robotData.longitudeSlam,headingAngle: robotData.headingSlam, status: formik.values.status});
                  }
                  }}>
                   Use Robot Location
                </div>
                <div className="flex items-center gap-1">
                  <label className="!ml-0 font-semibold text-textColor">Show On Map</label>
                  <Switch
                    id="show-on-map"
                    name="showOnMapSwitch"
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={showOnMap}
                    style={{
                      color: "#FFF",
                    }}
                    onChange={(e) => {
                      setShowOnMap(e.target.checked)
                    }}
                      
                  />
                </div>
              </div> */}

              <div className="flex justify-between w-fit max-w-[50%]">
                <div className="text-textColor px-2">
                  <div className="h-full">
                    <div
                      className="flex h-full text-center justify-center items-center gap-1 hover:cursor-pointer font-semibold "
                      onClick={handleClick}
                    >
                      Use Robot Location
                    </div>

                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      // anchorOrigin={{
                      //   vertical: 'bottom',
                      //   horizontal: 'left',
                      // }}

                      // MenuProps={{
                      //   anchorOrigin: {
                      //     vertical: "bottom",
                      //     horizontal: "left"
                      //   },
                      //   transformOrigin: {
                      //     vertical: "top",
                      //     horizontal: "left"
                      //   },
                      //   getContentAnchorEl: null
                      // }}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      open={open}
                      // onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "33ch",
                          padding: "0.3rem 1rem",
                          backgroundColor: "var(--primaryColor)",
                          border: "2px solid rgb(28,30,43)",
                          // backgroundColor: "rgb(28,30,43)",
                          color: "white",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        },
                      }}
                      sx={{
                        color: "white",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "var(--primaryColor)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "var(--primaryColor)",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "var(--primaryColor)",
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "var(--textColor) !important",
                        },

                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "var(--primaryColor)",
                          },
                      }}
                    >
                      {/* <FormControl variant="standard"sx={{ m: 1, minWidth: 120 }}> */}
                      <div className="flex justify-between items-center text-textColor">
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className=" text-center mr-2 pr-1 !text-textColor font-semibold"
                        >
                          Select Robot :{" "}
                        </InputLabel>
                        <Select
                          value={selectedRobot}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            height: "40px",
                            width: "180px",
                            color: "var(--textColor)",
                            border: "1px solid var(--textColor)",
                          }}
                          className=""
                        >
                          <MenuItem key={0} value={""} style={{}}>
                            No Robot Selected
                          </MenuItem>
                          {fleetData.robots.map((robot, index) => (
                            <MenuItem
                              key={index + 1}
                              value={robot.robotId}
                              style={{}}
                            >
                              {robot.robotId}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      {selectedRobot && selectedRobot !== "" && (
                        <div className="flex items-center text-textColor mt-2">
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="w-[111px] text-left mr-2 pr-1 text-textColor font-semibold"
                          >
                            Slam :{" "}
                          </InputLabel>
                          <Switch
                            id="slam"
                            name="useSlam"
                            inputProps={{ "aria-label": "controlled" }}
                            checked={useSlam}
                            style={{
                              color: "#FFF",
                            }}
                            onChange={(e) => {
                              setUseSlam(e.target.checked);
                            }}
                          />
                        </div>
                      )}
                      {/* </FormControl> */}
                    </Menu>
                  </div>
                </div>

                {/* <div className={`flex items-center gap-1 hover:cursor-pointer font-semibold ${useRobotLocation?"text-green-300":"text-textColor"}`} onClick={() => {
                  setUseRobotLocation((prev) => !prev)
                  if(selectedRobot && selectedRobot!==""){
                    let robotData = fleetData?.robots.find((robot)=>robot.robotId===selectedRobot)
                    formik.setValues({locationName: formik.values.locationName, locationType: formik.values.locationType, latitude: robotData.latitudeSlam, longitude : robotData.longitudeSlam,headingAngle: robotData.headingSlam, status: formik.values.status});
                  }
                  }}>
                   Use Robot Location
                </div> */}
                <div className="flex items-center gap-1 px-2">
                  <label className="!ml-0 font-semibold text-textColor">
                    Show On Map
                  </label>
                  <Switch
                    id="show-on-map"
                    name="showOnMapSwitch"
                    inputProps={{ "aria-label": "controlled" }}
                    checked={showOnMap}
                    style={{
                      color: "#FFF",
                    }}
                    onChange={(e) => {
                      setShowOnMap(e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="h-[calc(100%-89px)]">
              <form className="h-full" onSubmit={formik.handleSubmit}>
                <div className="overflow-scroll max-h-[calc(100%-124px)] ">
                  <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper flex-wrap gap-4 px-2 sm:px-5 max-h-full">
                    <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                      <label className="!ml-0 font-semibold">
                        Location Name
                      </label>
                      <TextField
                        id="location-name"
                        sx={{color: "var(--textColor)"}}
                        fullWidth
                        value={formik.values.locationName}
                        name="locationName"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.locationName &&
                          Boolean(formik.errors.locationName)
                        }
                        helperText={
                          formik.touched.locationName &&
                          formik.errors.locationName
                        }
                      />
                    </div>

                    <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                      <label className="!ml-0 font-semibold">
                        Select Location Type
                      </label>
                      <FormControl className="bg-bodycolor text-textColor">
                        <Autocomplete
                          options={locationTypeOptions}
                          helpertext={
                            formik.touched.locationType &&
                            formik.errors.locationType
                          }
                          sx={{
                            '& .MuiInputBase-root': {
                              color: 'var(--textColor)', // Changes input text color
                            },
                            '& .MuiInputLabel-root': {
                              color: 'var(--textColor)', // Changes label color
                            },
                          }}
                          value={
                            formik.values.locationType === ""
                              ? null
                              : locationTypeOptions.find((option) => {
                                  return option === formik.values.locationType;
                                }) || null
                          }
                          onChange={(_, selectedOption) => {
                            formik.setFieldValue(
                              "locationType",
                              selectedOption
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="text-[rgb(189,196,224)] !m-0 !p-[5px_5px_5px_10px] text-sm"
                            />
                          )}
                        />
                      </FormControl>
                      <div>
                        {" "}
                        {formik.errors.locationType &&
                        formik.touched.locationType ? (
                          <p className="text-[#d32f2f] text-xs -mt-[2px] text-left !pl-0">
                            Location Type is req.
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="w-[100%] sm:w-[30%] flex items-center gap-1">
                      <label className="!ml-0 font-semibold">Status</label>
                      <Switch
                        id="location-status"
                        name="status"
                        checked={formik.values.status}
                        style={{
                          color: "#FFF",
                        }}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                        helpertext={
                          formik.touched.status && formik.errors.status
                        }
                      />
                    </div>

                    <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                      <label className="!ml-0 font-semibold">Latitude</label>
                      <TextField
                        id="location-latitude"
                        fullWidth
                        value={formik.values.latitude}
                        name="latitude"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.latitude &&
                          Boolean(formik.errors.latitude)
                        }
                        helperText={
                          formik.touched.latitude && formik.errors.latitude
                        }
                      />
                    </div>

                    <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                      <label className="!ml-0 font-semibold">Longitude</label>
                      <TextField
                        id="location-longitude"
                        fullWidth
                        value={formik.values.longitude}
                        name="longitude"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.longitude &&
                          Boolean(formik.errors.longitude)
                        }
                        helperText={
                          formik.touched.longitude && formik.errors.longitude
                        }
                      />
                    </div>

                    <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                      <label className="!ml-0 font-semibold">Altitude</label>
                      <TextField
                        id="location-altitude"
                        fullWidth
                        value={formik.values.altitude}
                        name="altitude"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.a &&
                          Boolean(formik.errors.altitude)
                        }
                        helperText={
                          formik.touched.altitude && formik.errors.altitude
                        }
                      />
                    </div>

                    <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                      <label className="!ml-0 font-semibold">
                        Heading Angle
                      </label>
                      <TextField
                        id="location-heading-angle"
                        fullWidth
                        value={formik.values.headingAngle}
                        name="headingAngle"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.headingAngle &&
                          Boolean(formik.errors.headingAngle)
                        }
                        helperText={
                          formik.touched.headingAngle &&
                          formik.errors.headingAngle
                        }
                      />
                    </div>
                    <div className="w-full">
                      <div
                        className={`max-w-fit ml-auto rounded-lg text-grey mr-2 hover:cursor-pointer flex items-center`}
                        onClick={() => setShowAdvancedSettings((prev) => !prev)}
                      >
                        Advanced Settings{" "}
                        {!showAdvancedSettings ? (
                          <IoMdArrowDropdown />
                        ) : (
                          <IoMdArrowDropup />
                        )}
                      </div>
                    </div>

                    {showAdvancedSettings && (
                      <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                        <label className="!ml-0 font-semibold">
                          Select Group Type
                        </label>
                        <FormControl className="bg-bodycolor text-textColor">
                          <Autocomplete
                            options={groupTypeOptions}
                            sx={{
                              '& .MuiInputBase-root': {
                                color: 'var(--textColor)', // Changes input text color
                              },
                              '& .MuiInputLabel-root': {
                                color: 'var(--textColor)', // Changes label color
                              },
                            }}
                            helpertext={
                              formik.touched.groupType &&
                              formik.errors.groupType
                            }
                            value={
                              formik.values.groupType === ""
                                ? null
                                : groupTypeOptions.find((option) => {
                                    return option === formik.values.groupType;
                                  }) || null
                            }
                            onChange={(_, selectedOption) => {
                              formik.setFieldValue("groupType", selectedOption);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className=" !m-0 !p-[5px_5px_5px_10px] text-sm"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    )}
                    {showAdvancedSettings &&
                    formik.values.groupType === "Custom" ? (
                      <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                        <label className="!ml-0 font-semibold">Group</label>
                        <TextField
                          id="location-group"
                          fullWidth
                          value={formik.values.group}
                          name="group"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.group && Boolean(formik.errors.group)
                          }
                          helperText={
                            formik.touched.group && formik.errors.group
                          }
                        />
                      </div>
                    ) : null}

                    {showAdvancedSettings && (
                      <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                        <label className="!ml-0 font-semibold">
                          Position X
                        </label>
                        <TextField
                          id="location-position-x"
                          fullWidth
                          value={formik.values.positionX}
                          name="positionX"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.positionX &&
                            Boolean(formik.errors.positionX)
                          }
                          helperText={
                            formik.touched.positionX && formik.errors.positionX
                          }
                        />
                      </div>
                    )}

                    {showAdvancedSettings && (
                      <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                        <label className="!ml-0 font-semibold">
                          Position Y
                        </label>
                        <TextField
                          id="location-position-y"
                          fullWidth
                          value={formik.values.positionY}
                          name="positionY"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.positionY &&
                            Boolean(formik.errors.positionY)
                          }
                          helperText={
                            formik.touched.positionY && formik.errors.positionY
                          }
                        />
                      </div>
                    )}

                    {showAdvancedSettings && (
                      <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                        <label className="!ml-0 font-semibold">
                          Position Z
                        </label>
                        <TextField
                          id="location-position-Z"
                          fullWidth
                          value={formik.values.positionZ}
                          name="positionZ"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.positionZ &&
                            Boolean(formik.errors.positionZ)
                          }
                          helperText={
                            formik.touched.positionZ && formik.errors.positionZ
                          }
                        />
                      </div>
                    )}
                    {showAdvancedSettings && (
                      <div className="w-[100%] sm:w-[30%] flex flex-col gap-1 ">
                        <label className="!ml-0 font-semibold">
                          Position Yaw
                        </label>
                        <TextField
                          id="location-position-yaw"
                          fullWidth
                          value={formik.values.positionYaw}
                          name="positionYaw"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.positionYaw &&
                            Boolean(formik.errors.positionYaw)
                          }
                          helperText={
                            formik.touched.positionYaw &&
                            formik.errors.positionYaw
                          }
                        />
                      </div>
                    )}

                    {showAdvancedSettings && (
                      <div className="w-[100%] flex flex-col items-start justify-center ml-3 gap-1">
                        <label className="!ml-0 font-semibold">Image</label>
                        <div className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer group">
                          {isLoading ? (
                            <div className="flex items-center justify-center w-full min-h-[150px]">
                              <Loader />
                            </div>
                          ) : (
                            <>
                              {!imageAsset ? (
                                <>
                                  <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer min-h-[150px]">
                                    <div className="flex flex-col items-center justify-center w-full h-full">
                                      <MdCloudUpload className="text-3xl text-gray-500 hover:!text-ottonomyBlue" />
                                      <p className="text-gray-500 hover:text-ottonomyBlue">
                                        Click here to upload
                                      </p>
                                    </div>
                                    <input
                                      type="file"
                                      name="uploadImage"
                                      aceept="image/*"
                                      onChange={uploadImage}
                                      className="hidden"
                                    />
                                  </label>
                                </>
                              ) : (
                                <>
                                  <div className="relative h-full p-3">
                                    <img
                                      src={imageAsset}
                                      alt="uploadedImage"
                                      className="object-cover w-full h-full"
                                    />
                                    <button
                                      type="button"
                                      className="absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                                      onClick={() => {
                                        setImageAsset(null);
                                        setFile(null);
                                      }}
                                    >
                                      <MdDelete className="!text-textColor fill-white" />
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex-wrap gap-4 px-4 p- sm:px-5 max-h-[calc(100%-89px)] ">
                    {showOnMap && (
                      <MapboxLocation
                        fleetData={fleetData}
                        latitude={
                          isLatLongValid() ? formik.values.latitude : 28
                        }
                        showMarker={isLatLongValid() || showMarker}
                        longitude={
                          isLatLongValid() ? formik.values.longitude : 77
                        }
                        headingAngle={
                          isLatLongValid() ? formik.values.headingAngle : 0
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between px-3 Order_modal_button_wrapper sm:relative">
                  <button
                    onClick={() => {
                      formik.resetForm();
                      setIsLoading(false);
                      setImageAsset(null);
                      setFile(null);
                      setAddLocationModalOpen(false);
                      setSelectedRobot("");
                    }}
                    className="!p-[10px_20px] m-[20px] font-bold text-textColor transition-all border-2 rounded-lg bg-ottonomyDarkBlue border-ottonomyBlue hover:bg-ottonomyBlue"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="transition-all Order_save_button !text-secTextColor hover:bg-ottonomyBlue"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default AddLocationModal;
